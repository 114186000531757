import React from 'react'
import Head from 'next/head'

import 'assets/main.scss'

import { I18nProvider } from 'next-localization'
import type { AppProps } from 'next/app'

import reportAccessibility from '../utils/reportAccessibility'
import LocalNavContextProvider from 'components/front-end/_LocalNavContext'
import { SitecorePageProps } from 'lib/page-props'

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps

  return (
    // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
    // Note Next.js does not (currently) provide anything for translation, only i18n routing.
    // If your app is not multilingual, next-localization and references to it can be removed.
    <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
      <>
        <Head>
          <link rel="preload" href="/fonts/Inter-Regular.ttf" as="font" crossOrigin="anonymous"></link>
          <link rel="preload" href="/fonts/Inter-Medium.ttf" as="font" crossOrigin="anonymous"></link>
          <link rel="preload" href="/fonts/Inter-SemiBold.ttf" as="font" crossOrigin="anonymous"></link>

          <link rel="preload" href="/fonts/Uniform-Condensed-Regular.woff2" as="font" crossOrigin="anonymous"></link>
          <link rel="preload" href="/fonts/Uniform-Condensed-Medium.woff2" as="font" crossOrigin="anonymous"></link>
          <link rel="preload" href="/fonts/Uniform-Condensed-Bold.woff2" as="font" crossOrigin="anonymous"></link>
        </Head>
        <LocalNavContextProvider>
          <Component {...rest} />
        </LocalNavContextProvider>
      </>
    </I18nProvider>
  )
}

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development' && !process.env.NEXT_PUBLIC_SKIP_AXE) {
  // Set timeout to allow for page to render fully before running accessibility checks
  setTimeout(() => {
    reportAccessibility(React)
  }, 3000)
}

export default App
