import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'

import usePrevious from 'utils/usePrevious'

export const LocalNavContext = createContext({
  localNav: false,
})

// Context that tracks whether the user has navigated to a new page. This does not handle
// people going back in their browser history.
export default function LocalNavContextProvider({ children }: PropsWithChildren) {
  const [localNav, setLocalNav] = useState(false)

  const pathName = usePathname()
  const oldPathname = usePrevious(pathName)

  useEffect(() => {
    if (!localNav && oldPathname && oldPathname !== pathName) {
      setLocalNav(true)
    }
  }, [localNav, oldPathname, pathName])

  return (
    <LocalNavContext.Provider
      value={{
        localNav,
      }}
    >
      {children}
    </LocalNavContext.Provider>
  )
}

export function useLocalNavContext() {
  const ctx = useContext(LocalNavContext)

  if (ctx === undefined) {
    throw new Error('useLocalNavContext must be used within a LocalNavContextProvider')
  }

  return ctx.localNav
}

